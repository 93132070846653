import {DocumentNode, OperationVariables, QueryHookOptions, useQuery} from '@apollo/client'
import isServerSide from '@helpers/misc/isServerSide'
import {useCallback, useRef, useState} from 'react'

class CursorBasedPaginationCache {
  private cacheKey: string

  constructor(cacheKey: string, pageSize) {
    this.cacheKey = `${cacheKey}-${pageSize}-pageCache`

    if (isServerSide()) return

    if (!localStorage.getItem(this.cacheKey)) {
      localStorage.setItem(this.cacheKey, '{}')
    }
  }

  getPage(id: string): number {
    if (isServerSide()) return undefined

    const cache = localStorage.getItem(this.cacheKey) || '{}'
    return JSON.parse(cache)[id]
  }

  hasPage(id: string) {
    return this.getPage(id) !== undefined
  }

  setPage(id: string, value: number) {
    if (isServerSide()) return

    const cache = localStorage.getItem(this.cacheKey) || '{}'
    const cacheObj = JSON.parse(cache)
    cacheObj[id] = value
    localStorage.setItem(this.cacheKey, JSON.stringify(cacheObj))
  }
}

export function onPageLoad<T extends {_id?: string}>(
  cacheKey: string,
  pageSize: number,
  items: T[],
  loadedPageNumber: number,
) {
  const cache = new CursorBasedPaginationCache(cacheKey, pageSize)

  for (const item of items || []) {
    if (!cache.hasPage(item._id)) {
      cache.setPage(item._id, loadedPageNumber)
    }
  }
}

export type PaginatedQueryData<QueryFieldName extends string> = {
  [K in QueryFieldName]?: {
    totalCount?: number
    items?: {_id?: string}[]
  }
}

/**
 * Imitates the cursor-based pagination on the server side (we only have the page number, not the cursor)
 * To enable this, we store the page number of each item in the local storage.
 * In this way, we can know the page number of each item and avoid fetching the same page multiple times.
 */
export function useCursorBasedPagination<
  QueryFieldName extends string,
  TData extends PaginatedQueryData<QueryFieldName> = PaginatedQueryData<QueryFieldName>,
  TVariables extends OperationVariables = OperationVariables,
>(
  queryFieldName: QueryFieldName,
  paginatedQuery: DocumentNode,
  paginationOptions: {
    pageSize?: number
    cacheKey?: string
  } = {pageSize: 10, cacheKey: queryFieldName},
  opts?: QueryHookOptions<TData, TVariables>,
) {
  const [loading, setLoading] = useState(false)
  const [fetchingMore, setFetchingMore] = useState(false)
  const [fetchedPages, setFetchedPages] = useState(new Set([1]))
  const loadingTimeoutRef = useRef(null)

  const queryResult = useQuery<TData, TVariables>(paginatedQuery, {
    fetchPolicy: 'cache-first',
    ...opts,
    variables: {
      limit: paginationOptions.pageSize,
      ...opts.variables,
    },
  })

  const {data} = queryResult

  let nextPage = 1
  while (fetchedPages.has(nextPage)) {
    nextPage += 1
  }

  const hasPage = (nextPage: number) =>
    (nextPage - 1) * paginationOptions.pageSize < (data?.[queryFieldName]?.totalCount || 0) &&
    data?.[queryFieldName]?.items?.length < data?.[queryFieldName]?.totalCount

  const fetchMore = useCallback(
    (customPage?: number) => {
      const page = customPage || nextPage
      if (fetchingMore) return
      // Do not fetch if we have fetched all the items
      if (data?.[queryFieldName]?.items?.length >= data?.[queryFieldName]?.totalCount) return
      // Do not fetch if we have fetched all the pages
      if (fetchedPages.has(page)) return

      if (!hasPage(page)) return

      setFetchedPages(new Set([...fetchedPages, page]))
      setFetchingMore(true)
      setLoading(true)

      return queryResult
        .fetchMore({variables: {...opts.variables, page}})
        .then(({data}) => {
          onPageLoad(
            paginationOptions.cacheKey,
            paginationOptions.pageSize,
            data?.[queryFieldName]?.items,
            page,
          )

          return data
        })
        .finally(() => {
          setFetchingMore(false)
          clearTimeout(loadingTimeoutRef.current)

          // Delay the loading state back to false to prevent flickering
          loadingTimeoutRef.current = setTimeout(() => setLoading(false), 300)
        })
    },
    [data?.[queryFieldName]?.items?.length, loading, nextPage, opts.variables, fetchedPages],
  )

  return {
    data,
    loading,
    fetchMore,
    hasNextPage: hasPage(nextPage),
    nextPage,
  }
}
