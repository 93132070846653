import {productListInformationWithoutStockFragment} from '@data/fragments/products/productListInformation.main'
import gql from 'graphql-tag'

export default gql`
  fragment orderCategory on PaginatedCategoryInMenu {
    _id
    name
    products(menuId: $menuId, limit: $productLimit) {
      _id
      categories {
        _id
        internalName
      }
      modifiers {
        _id
        optional
      }
      ...productListInformationWithoutStock
    }
  }
  ${productListInformationWithoutStockFragment}
`
