import orderCategory from '@data/fragments/categories/orderCategory.main'
import gql from 'graphql-tag'

export const categoriesForRestaurantQuery = gql`
  query getCategoriesAndProductsWeb_cached($websiteId: ID, $menuId: ID, $format: String, $limit: BigInt, $page: BigInt, $productLimit: Float) {
    categories: categoriesInMenu(menuId: $menuId, websiteId: $websiteId, limit: $limit, page: $page) {
      totalCount
      items {
        _id
        ...orderCategory
        sorting {
          type
          order
        }
        brand {
          _id
          name
          link
          menuImage {
            _id
            url
          }
          categoriesImage {
            _id
            url
          }
        }
      }
    }
  }
  ${orderCategory}
`
