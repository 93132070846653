import {GetCategoriesAndProductsWeb_CachedQuery} from '@data/__generated__/types.main'
import {useCategoriesAndProductsUsingPreferences} from '@data/queries/categories/getCategoriesAndProducts.main'
import {useUserPreferencesPartial} from '@data/queries/preferences/userPreferences.main'
import {useWebsiteMenuInformation} from '@data/queries/website/websiteMenuInformation.main'
import useShouldHidePrice from '@hooks/useShouldHidePrice'
import Category from '../../Category'
import Loading from '../../Loading'

export default function SearchedResults({
  searchResults,
}: {
  searchResults: GetCategoriesAndProductsWeb_CachedQuery['categories']['items'][0]['products']
}) {
  const {data} = useCategoriesAndProductsUsingPreferences()
  const categories = data?.categories
  const menuId = useUserPreferencesPartial()?.data?.userPreferences?.menuId
  const hidePrice = useShouldHidePrice()
  const {website} = useWebsiteMenuInformation()

  if (!categories || !searchResults) return <Loading />

  const category = {
    _id: 'search',
    name: 'Resultados',
    products: searchResults,
  }

  if (searchResults.length === 0) {
    return <div>No se encontraron resultados</div>
  }

  return (
    <Category
      category={category}
      menuId={menuId}
      hidePrice={hidePrice}
      hideNotAvailableProducts={website.hideNotAvailableProducts}
      isSearchResult
    />
  )
}
