import {
  CategoryProducts_CachedQuery,
  CategoryProducts_CachedQueryVariables,
} from '@data/__generated__/types.main'
import {productListInformationWithoutStockFragment} from '@data/fragments/products/productListInformation.main'
import {useCursorBasedPagination} from '@hooks/useCursorBasedPagination'
import useMenuId from '@hooks/useMenuId'
import useWebsiteId from '@hooks/useWebsiteId'
import gql from 'graphql-tag'
import {} from 'react'

export const PRODUCTS_PAGE_SIZE = 20

export const categoryProductsQuery = gql`
  query categoryProducts_cached($websiteId: ID!, $categoryId: ID!, $format: String, $menuId: ID, $limit: BigInt, $page: BigInt, $excludeUnavailable: Boolean) {
    products(categoryId: $categoryId, limit: $limit, page: $page, websiteId: $websiteId, excludeUnavailable: $excludeUnavailable, menuId: $menuId) {
      totalCount
      items {
        _id
        categories {
          _id
          internalName
        }
        modifiers {
          _id
          optional
        }
        ...productListInformationWithoutStock
      }
    }
  }
  ${productListInformationWithoutStockFragment}
`

export default function useCategoryProducts(
  categoryId: string,
  opts: {excludeUnavailable?: boolean; skip?: boolean} = {},
) {
  const websiteId = useWebsiteId()
  const menuId = useMenuId()

  const variables = {
    websiteId,
    categoryId,
    format: 'webp',
    excludeUnavailable: opts.excludeUnavailable,
    menuId,
  }

  const result = useCursorBasedPagination<
    'products',
    CategoryProducts_CachedQuery,
    CategoryProducts_CachedQueryVariables
  >(
    'products',
    categoryProductsQuery,
    {pageSize: PRODUCTS_PAGE_SIZE, cacheKey: `categoryProducts-${categoryId}`},
    {variables, skip: opts.skip},
  )

  return result
}
