import React from 'react'

export default function Skeleton() {
  return (
    <div className="flex flex-col gap-4 animate-pulse">
      <div className="px-2 py-2 w-52 h-10 flex cursor-pointer bg-gray-100 dark:bg-gray-500 rounded" />
      <div className="px-2 py-2 h-40 flex cursor-pointer bg-gray-100 dark:bg-gray-500 rounded" />
    </div>
  )
}

export const ProductSkeleton = () => {
  return (
    <div className="relative bg-white rounded-lg flex shadow-[4px_0_20px_0px_rgba(0,0,0,0.08)] h-40 w-[95dvw] sm:w-96 relative flex flex-col gap-4 animate-pulse">
      <div className="px-2 py-2 w-full h-40 flex cursor-pointer bg-gray-100 dark:bg-gray-500 rounded" />
    </div>
  )
}
