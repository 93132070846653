import {useCategoriesAndProductsUsingPreferences} from '@data/queries/categories/getCategoriesAndProducts.main'
import {useUserPreferencesPartial} from '@data/queries/preferences/userPreferences.main'
import {useWebsiteMenuInformation} from '@data/queries/website/websiteMenuInformation.main'
import {FirstCategoryContext} from '@helpers/websites/FirstCategoryContext'
import useOnEnterViewport from '@hooks/useOnEnterViewport'
import useShouldHidePrice from '@hooks/useShouldHidePrice'
import {Fragment, useRef} from 'react'
import {CategoriesRefMap} from '../..'
import BrandBar from '../../BrandBar'
import Category from '../../Category'
import Skeleton from '../../Category/Skeleton'
import Loading from '../../Loading'
import NoBrandDivider from '../../NoBrandDivider'

export default function Categories({
  categoriesRefs,
}: {
  categoriesRefs: CategoriesRefMap
}) {
  const menuId = useUserPreferencesPartial()?.data?.userPreferences?.menuId
  const hidePrice = useShouldHidePrice()
  const {website} = useWebsiteMenuInformation()
  const sentinelRef = useRef(null)
  const {data, loading: nextPageLoading, fetchMore} = useCategoriesAndProductsUsingPreferences()
  const categories = data?.categories

  useOnEnterViewport({
    sentinelRef,
    onEnterViewport: fetchMore,
    notifyMultipleTimes: false,
    rootMargin: 1000,
  })

  if (!categories) return <Loading />

  const visibleCategories = !categories
    ? []
    : categories.items.filter(category => category.products.length)
  let lastBrandId = null
  const isMultiBrand = website.isMultiBrand

  return (
    <>
      {visibleCategories.map((category, index) => {
        let brand = null
        if (category.brand && category.brand._id !== lastBrandId) {
          brand = category.brand
          lastBrandId = brand._id
        }

        const dividerContent =
          !category.brand && lastBrandId ? <NoBrandDivider key={lastBrandId} /> : null
        const brandContent = brand ? <BrandBar brand={brand} key={brand._id} /> : null

        if (!category.brand && lastBrandId) {
          lastBrandId = null
        }

        return (
          <Fragment key={category._id}>
            {isMultiBrand && (
              <>
                {brandContent}
                {dividerContent}
              </>
            )}
            <div
              id={category._id}
              ref={ref => {
                if (ref) {
                  categoriesRefs[category._id] = ref
                }
              }}
            >
              <FirstCategoryContext.Provider value={index === 0}>
                <Category
                  category={category}
                  menuId={menuId}
                  hidePrice={hidePrice}
                  hideNotAvailableProducts={website.hideNotAvailableProducts}
                />
              </FirstCategoryContext.Provider>
            </div>
          </Fragment>
        )
      })}
      <div ref={sentinelRef} />
      {nextPageLoading && <Skeleton />}
    </>
  )
}
