import {
  GetActiveLoyaltyProgramConfig_CachedQuery,
  GetActiveLoyaltyProgramConfig_CachedQueryVariables
} from '@data/__generated__/types.main'
import {useApolloQuery as useQuery} from 'apollo-hooks'
import gql from 'graphql-tag'

export default function useWebsiteLoyaltyConfig(websiteId: string) {
  const {loyaltyProgramConfig, loading, error} = useQuery<
    GetActiveLoyaltyProgramConfig_CachedQuery,
    GetActiveLoyaltyProgramConfig_CachedQueryVariables
  >({
    query: gql`
      query getActiveLoyaltyProgramConfig_cached($websiteId: ID) {
        loyaltyProgramConfig(websiteId: $websiteId) {
          _id
          name
          active
          maxRedeemAmount
          minRedeemAmount
          accumulationRate
          blockRedeemWithCoupon
          maxRedeemPercentage
        }
      }
    `,
    variables: {websiteId},
    omit: !websiteId
  })

  return {loyaltyProgramConfig, loading, error}
}
