import {useSuspenseQuery} from '@apollo/client'
import {
  GetCategoriesAndProductsWeb_CachedQuery,
  GetCategoriesAndProductsWeb_CachedQueryVariables,
} from '@data/__generated__/types.main'
import {categoriesForRestaurantQuery} from '@data/queries/categories/categoriesForWebsite.main'
import isServerSide from '@helpers/misc/isServerSide'
import {useCursorBasedPagination} from '@hooks/useCursorBasedPagination'
import useMenuId from '@hooks/useMenuId'
import useWebsiteId from '@hooks/useWebsiteId'
import {PRODUCTS_PAGE_SIZE} from '@page-components/Order/Products/ProductsContent/Category/hooks/useCategoryProducts.main'
import {} from 'react'
import {useDefaultMenuId} from './getDefaultMenu.main'

export interface Props {
  websiteId: string
  menuId?: string
  format?: string
  omit?: boolean
}

const CATEGORIES_PAGE_SIZE = 10

export default function useCategoriesAndProducts({
  websiteId,
  menuId,
  format = 'webp',
  omit = false,
}: Props) {
  const defaultMenuId = useDefaultMenuId()

  const variables = {
    websiteId,
    menuId,
    format,
    limit: CATEGORIES_PAGE_SIZE,
    productLimit: PRODUCTS_PAGE_SIZE,
  }

  const defaultMenu = useSuspenseQuery<
    GetCategoriesAndProductsWeb_CachedQuery,
    GetCategoriesAndProductsWeb_CachedQueryVariables
  >(categoriesForRestaurantQuery, {
    variables: {
      ...variables,
      menuId: defaultMenuId,
    },
    fetchPolicy: 'cache-first',
    skip: omit,
  })

  /**
   * We do one cached query for the default menu and one partial query for the current menu.
   * In this way, we can render the default menu while the current menu is loading.
   * By doing this, there should be no loading time between the default menu and the current menu.
   */
  const result = useCursorBasedPagination<
    'categories',
    GetCategoriesAndProductsWeb_CachedQuery,
    GetCategoriesAndProductsWeb_CachedQueryVariables
  >(
    'categories',
    categoriesForRestaurantQuery,
    {pageSize: CATEGORIES_PAGE_SIZE},
    {
      variables,
      skip: omit || isServerSide() || !menuId,
    },
  )

  const data = result?.data?.categories ? result.data : defaultMenu.data

  return {
    data,
    fetchMore: result.fetchMore,
    loading: result.loading,
    loadedCategories: new Set(data?.categories?.items?.map(category => category._id)),
    hasNextPage: result.hasNextPage,
    currentPage: result.nextPage - 1,
  }
}

export const useCategoriesAndProductsUsingPreferences = () => {
  const websiteId = useWebsiteId()
  const menuId = useMenuId()

  return useCategoriesAndProducts({
    websiteId,
    menuId,
  })
}

export const useFetchUntilCategoryFound = () => {
  let {currentPage, hasNextPage, fetchMore, data, loadedCategories} =
    useCategoriesAndProductsUsingPreferences()

  async function loadUntilFound(categoryId: string) {
    while (hasNextPage && !loadedCategories.has(categoryId)) {
      const nextPage = currentPage + 1

      const fetchResult = await fetchMore(nextPage)

      currentPage = nextPage

      hasNextPage = currentPage * CATEGORIES_PAGE_SIZE < data.categories.totalCount

      for (const category of fetchResult?.categories?.items ?? []) {
        loadedCategories.add(category._id)
      }
    }

    return {found: loadedCategories.has(categoryId)}
  }

  return loadUntilFound
}
