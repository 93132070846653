import {
  CategoryProducts_CachedQuery,
  GetOutOfStockProductsInListQuery,
  GetOutOfStockProductsInListQueryVariables,
} from '@data/__generated__/types.main'
import {useUserPreferencesPartial} from '@data/queries/preferences/userPreferences.main'
import {getOutOfStockProductsInListQuery} from '@data/queries/stock/getOutOfStockProductsInList.main'
import isServerSide from '@helpers/misc/isServerSide'
import useWebsiteId from '@hooks/useWebsiteId'
import {useApolloQuery} from 'apollo-hooks'

export type ProductWithStockInfo = CategoryProducts_CachedQuery['products']['items'][0] & {
  isOutOfStock?: boolean
}
export default function useAddStockInfoToProducts(
  categoriesOrProductIds: {
    categoriesIds?: string[]
    productsIds?: string[]
  },
  products: CategoryProducts_CachedQuery['products']['items'],
  opts?: {skip?: boolean},
): ProductWithStockInfo[] {
  const websiteId = useWebsiteId()
  const preferencesV2 = useUserPreferencesPartial(websiteId)
  const {storeId, deliverAt} = preferencesV2?.data?.userPreferences ?? {}

  const {outOfStockIds} = useApolloQuery<
    GetOutOfStockProductsInListQuery,
    GetOutOfStockProductsInListQueryVariables
  >({
    query: getOutOfStockProductsInListQuery,
    variables: {
      websiteId,
      ...(categoriesOrProductIds.categoriesIds
        ? {categoriesIds: categoriesOrProductIds.categoriesIds}
        : {productsIds: categoriesOrProductIds.productsIds}),
      storeId,
      deliverAt,
    },
    partial: true,
    omit: !products || !storeId || isServerSide() || opts?.skip,
  })

  if (!outOfStockIds || !products) return products

  const outOfStockIdsMap = new Map(outOfStockIds?.map(id => [id, true]))

  return products.map(product => {
    return {...product, isOutOfStock: !!outOfStockIdsMap.get(product._id)}
  })
}
