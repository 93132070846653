import {
  GetWebsiteCoinsBalanceQuery,
  GetWebsiteCoinsBalanceQueryVariables
} from '@data/__generated__/types.main'
import {useApolloQuery as useQuery} from 'apollo-hooks'
import gql from 'graphql-tag'

export default function useWebsiteCoinsBalanceNavBar(websiteId: string) {
  const {me, loading, error} = useQuery<
    GetWebsiteCoinsBalanceQuery,
    GetWebsiteCoinsBalanceQueryVariables
  >({
    query: gql`
      query getWebsiteCoinsBalance($websiteId: ID) {
        me {
          _id
          websiteCoinsBalance(websiteId: $websiteId)
        }
      }
    `,
    variables: {websiteId},
    omit: !websiteId,
    fetchPolicy: 'cache-first'
  })

  return {
    me,
    loading,
    error
  }
}
