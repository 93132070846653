import productListInformationFragment from '@data/fragments/products/productListInformation.main'
import {useUserPreferences} from '@data/queries/preferences/userPreferences.main'
import useCountryCode from '@hooks/useCountryCode'
import useWebsiteId from '@hooks/useWebsiteId'
import {useApolloQuery} from 'apollo-hooks'
import gql from 'graphql-tag'
import {useEffect} from 'react'
import {useProductPaginationContext} from '../ProductPaginationContext'

export default function SearchProduct(props) {
  const {onProductSearched} = props
  const {filter} = useProductPaginationContext()
  const data = useUserPreferences()
  const websiteId = useWebsiteId()
  const countryCode = useCountryCode()
  const {storeId, deliverAt, menuId} = data

  const {products, loading} = useApolloQuery({
    query: gql`
      query getProductsSearched(
        $websiteId: ID
        $searchTerm: String
        $countryCode: ID
        $menuId: ID!
        $storeId: ID
        $deliverAt: Date
        $format: String
      ) {
        products: searchItems(
          websiteId: $websiteId
          searchTerm: $searchTerm
          countryCode: $countryCode
          menuId: $menuId
          storeId: $storeId
          deliverAt: $deliverAt
        ) {
          _id
          ...productListInformation
        }
      }
      ${productListInformationFragment}
    `,
    variables: {
      websiteId,
      searchTerm: filter,
      countryCode,
      menuId,
      storeId,
      deliverAt,
    },
    fetchPolicy: 'cache-first',
    omit: !filter || !menuId,
  })

  useEffect(() => {
    if (!filter || loading) return
    onProductSearched(products)
  }, [filter, onProductSearched])

  return null
}
