import throttle from 'lodash/throttle'
import React, {useEffect, useState} from 'react'

export default function useOnEnterViewport({
  sentinelRef,
  onEnterViewport,
  rootMargin = 100,
  notifyMultipleTimes = true,
  notifyDebounce = 1000,
}: {
  sentinelRef: React.RefObject<HTMLDivElement>
  onEnterViewport: () => void
  rootMargin?: number
  notifyMultipleTimes?: boolean
  notifyDebounce?: number
}) {
  const [isVisible, setIsVisible] = useState(false)
  const callback = throttle(onEnterViewport, notifyDebounce)

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        // Check if the sentinel is visible
        if (entries[0].isIntersecting) {
          callback()
          setIsVisible(true)
        } else {
          setIsVisible(false)
        }
      },
      {
        rootMargin: `${rootMargin}px`, // Trigger the callback when the sentinel comes within the given margin of the viewport
        threshold: 1.0, // Trigger when 100% of the sentinel is visible
      },
    )

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current)
    }

    return () => {
      if (sentinelRef.current) {
        observer.unobserve(sentinelRef.current)
      }
    }
  }, [sentinelRef, onEnterViewport, rootMargin])

  useEffect(() => {
    if (notifyMultipleTimes && isVisible) {
      const interval = setInterval(() => {
        callback()
      }, notifyDebounce)

      return () => clearInterval(interval)
    }
  }, [isVisible])
}
