import classnames from '@helpers/misc/classnames'
import WebsiteCoinsImage from '@page-components/CheckoutV3/sections/WebsiteCoins/WebsiteCoinsCount/WebsiteCoinsImage'
import Link from 'next/link'

import useWebsiteCoinsBalanceNavBar from './useWebsiteCoinsBalanceNavBar.main'

export default function WebsiteCoin({websiteId, config, asLink = false, bannerCustomClass = ''}) {
  const {me} = useWebsiteCoinsBalanceNavBar(websiteId)
  if (!me?.websiteCoinsBalance && me?.websiteCoinsBalance !== 0) return null

  const bannerClasses = classnames(
    'flex py-3 px-7 rounded-lg text-white justify-center primaryBackgroundColor',
    bannerCustomClass,
    {'cursor-pointer': asLink}
  )
  const bannerContent = (
    <div className="flex items-center">
      <WebsiteCoinsImage />
      <span className="text-lg ml-2" suppressHydrationWarning>
        Tienes {me.websiteCoinsBalance} {config.name}
      </span>
    </div>
  )

  return asLink ? (
    <Link href="/justoloyalty" passHref legacyBehavior>
      <div className={bannerClasses}>{bannerContent}</div>
    </Link>
  ) : (
    <div className={bannerClasses}>{bannerContent}</div>
  )
}
