import {useUserPreferencesPartial} from '@data/queries/preferences/userPreferences.main'
import {useWebsiteMenuInformation} from '@data/queries/website/websiteMenuInformation.main'
import hasWebpSupport from '@helpers/misc/hasWebpSupport'
import isServerSide from '@helpers/misc/isServerSide'
import useWebsiteId from '@hooks/useWebsiteId'
import {} from '@page-components/OrderV2/useSelectedProduct'
import React from 'react'

import useCategoriesAndProducts from '../../../data/queries/categories/getCategoriesAndProducts.main'

import ProductsContent from './ProductsContent'
import {ProductPaginationContextProvider} from './ProductsContent/ProductPaginationContext'

const ProductsContentMemo = React.memo(ProductsContent)

export interface Props {
  skipServerPreload?: boolean
}

function ProductsInner(props: Props) {
  const {website} = useWebsiteMenuInformation()
  const preferencesV2 = useUserPreferencesPartial(website._id)
  const {menuId} = preferencesV2?.data?.userPreferences ?? {}
  const websiteId = useWebsiteId()

  const format = hasWebpSupport() ? 'webp' : 'jpeg'
  const {data} = useCategoriesAndProducts({
    websiteId,
    menuId,
    format,
    omit: isServerSide() && props.skipServerPreload,
  })
  const categories = data?.categories

  return (
    <ProductPaginationContextProvider>
      <ProductsContentMemo categories={categories} />
    </ProductPaginationContextProvider>
  )
}

export default function Products(props: Props) {
  return <ProductsInner {...props} />
}
