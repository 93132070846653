const easeInOutQuad = t => (t < 0.5 ? 2 * t * t : 1 - (-2 * t + 2) ** 2 / 2)

export const smoothScrollTo = (
  element: HTMLElement,
  {offset = 0, duration = 500, callback = () => {}},
) => {
  const targetPosition = element.getBoundingClientRect().top + window.scrollY
  const startPosition = window.scrollY
  const distance = targetPosition - startPosition + offset
  let start = null

  const step = timestamp => {
    if (!start) start = timestamp
    const progress = timestamp - start
    const percent = easeInOutQuad(Math.min(progress / duration, 1))
    window.scrollTo(0, startPosition + distance * percent)

    if (progress < duration) {
      window.requestAnimationFrame(step)
    } else {
      callback()
    }
  }

  window.requestAnimationFrame(step)
}
