import {useEffect} from 'react'
import {CategoriesRefMap} from '..'

export default function useObservedCategory({
  onActiveCategoryChange,
  categoryRefs,
}: {categoryRefs: CategoriesRefMap; onActiveCategoryChange: (categoryId: string) => void}) {
  useEffect(() => {
    const Observer = new IntersectionObserver(
      entries => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            onActiveCategoryChange(entry.target.id)
          }
        }
      },
      {
        rootMargin: '-50% 0px -50% 0px',
        root: null,
      },
    )

    for (const ref of Object.values(categoryRefs)) {
      Observer.observe(ref)
    }

    return () => {
      for (const ref of Object.values(categoryRefs)) {
        Observer.unobserve(ref)
      }
    }
  }, [categoryRefs, onActiveCategoryChange])
}
