import useIsDarkMode from '@hooks/useIsDarkMode'
import useRef from '@hooks/useRef'
import classnames from 'classnames'
import {useCallback, useEffect, useLayoutEffect, useState} from 'react'

import {useRouter} from 'next/router'
import slugify from 'slugify'
import {CategoriesRefMap, ScrollToSignature} from '..'
import styles from './styles.module.css'
import useCategoriesMinimal from './useCategoriesMinimal.main'
import useObservedCategory from './useObservedCategory'

export interface Props {
  onClick: () => void
  scrollTo: ScrollToSignature
  categoryRefs: CategoriesRefMap
}

function CategoryNavigationBarInner(
  props: Props & {containerRef: React.RefObject<HTMLDivElement>},
) {
  const {onClick, categoryRefs} = props
  const [activeCategoryId, setActiveCategoryId] = useState(null)
  const [observerDisabled, setObserverDisabled] = useState(false)

  const categories = useCategoriesMinimal() || []
  const activeButtonRef = useRef(null)
  const router = useRouter()
  const setActiveCategoryIdIfNotDisabled = useCallback(
    categoryId => {
      if (observerDisabled) return
      setActiveCategoryId(categoryId)
    },
    [observerDisabled],
  )

  useObservedCategory({onActiveCategoryChange: setActiveCategoryIdIfNotDisabled, categoryRefs})

  useEffect(() => {
    const categoryName: string = router.query?.categoria as string
    if (!categoryName) return

    const categoryId = categories.find(
      category => slugify(category.name, {lower: true}) === slugify(categoryName, {lower: true}),
    )?._id

    if (categoryId) {
      props.scrollTo(categoryId)
    }
  }, [router.query, categories.length])

  /**
   * Scroll to the active category button when it is not fully visible
   */
  useLayoutEffect(() => {
    const target = activeButtonRef.current
    const container = props.containerRef.current

    if (target && container) {
      const targetRect = target.getBoundingClientRect()
      const containerRect = container.getBoundingClientRect()

      const targetLeft = targetRect.left - containerRect.left + container.scrollLeft
      const targetRight = targetLeft + targetRect.width

      if (targetLeft < container.scrollLeft) {
        container.scrollLeft = targetLeft
      } else if (targetRight > container.scrollLeft + containerRect.width) {
        container.scrollLeft = targetRight - containerRect.width
      }
    }
  }, [activeCategoryId])

  const darkMode = useIsDarkMode()

  return (
    <>
      {categories.map(category => {
        const isActive = activeCategoryId === category._id

        return (
          <a
            type="button"
            ref={isActive ? activeButtonRef : null}
            className={classnames(
              styles.category,
              'border-b-4 border-transparent primaryColorHover',
              darkMode ? styles.clearText : styles.darkText,
              isActive ? 'primaryBorderColor primaryColor' : '',
            )}
            key={category._id}
            // biome-ignore lint/a11y/useValidAnchor: <explanation>
            onClick={() => {
              setObserverDisabled(true)
              onClick()
              props.scrollTo(category._id, {
                callback: () => {
                  setObserverDisabled(false)
                  setActiveCategoryId(category._id)
                },
              })
            }}
          >
            {category.name}
          </a>
        )
      })}
    </>
  )
}

export default function CategoryNavBar(props: Props) {
  const containerRef = useRef(null)
  return (
    <div className={classnames(styles.container, 'flex gap-1')} ref={containerRef}>
      <CategoryNavigationBarInner {...props} containerRef={containerRef} />
    </div>
  )
}
