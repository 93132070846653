import ClientSuspense from '@components/ClientSuspense'
import TextSkeleton from '@components/V3/Text/TextSkeleton'
import {useLoginViaAuthWindow} from '@helpers/auth/loginViaAuth'
import useUserId from '@hooks/useUserId'
import useWebsiteId from '@hooks/useWebsiteId'
import useWebsiteLoyaltyConfig from '@page-components/CheckoutV3/hooks/useWebsiteLoyaltyConfig.main'
import WebsiteCoinsImage from '@page-components/CheckoutV3/sections/WebsiteCoins/WebsiteCoinsCount/WebsiteCoinsImage'
import useShouldUseLoyaltyV2 from '@page-components/CheckoutV3/sections/useShouldUseLoyaltyV2'
import useNavbarV2 from '@page-components/Layout/Website/Layouts/Default/NavbarV2/hooks/useNavbarV2'
import {useTranslation} from 'next-i18next'
import {Suspense} from 'react'
import {MdKeyboardArrowRight as Caret} from 'react-icons/md'
import WebsiteCoin from './WebsiteCoin'

const LoggedOut = () => {
  const {t} = useTranslation('website', {keyPrefix: 'loyalty'})
  const websiteId = useWebsiteId()
  const {loyaltyProgramConfig: config} = useWebsiteLoyaltyConfig(websiteId)
  const loginViaAuth = useLoginViaAuthWindow('loyalty')
  const isNavbarV2Active = useNavbarV2()
  if (isNavbarV2Active) return null

  return (
    <div
      suppressHydrationWarning
      onClick={() => loginViaAuth()}
      className="primaryBackgroundColor relative mb-3 flex w-full max-w-3xl cursor-pointer items-center rounded-md px-7 py-4 text-white sm:flex-row"
    >
      <div>
        <WebsiteCoinsImage />
      </div>
      <div className="mb-1 ml-2 mr-7 flex flex-col">
        <span>
          ¡{t('getPoints')} <span className="font-bold">{config.name}</span>!
        </span>
        <span className="text-xs font-medium">{t('join')}</span>
      </div>
      <div className="ml-auto text-2xl">
        <Caret />
      </div>
    </div>
  )
}
const Skeleton = () => {
  return (
    <div className="relative mb-3 flex h-28 w-full max-w-3xl animate-pulse cursor-pointer items-center rounded-md bg-gray-100 px-7 py-4 text-white dark:bg-zinc-800 sm:h-20 sm:flex-row">
      <div className="mr-4 h-10 w-10 rounded-full bg-gray-200 dark:bg-zinc-600" />
      <div className="flex flex-grow flex-col">
        <TextSkeleton type={'subtitle'} className="w-4/5" />
        <TextSkeleton type={'caption'} />
      </div>
    </div>
  )
}

function InnerWebsiteCoins() {
  const isNavbarV2Active = useNavbarV2()
  const showJustoLoyaltyV2 = useShouldUseLoyaltyV2() && !isNavbarV2Active
  const websiteId = useWebsiteId()
  const userId = useUserId()
  const {loyaltyProgramConfig: config} = useWebsiteLoyaltyConfig(websiteId)
  if (!showJustoLoyaltyV2) return null
  if (!config?.active || !websiteId) return null
  return (
    <ClientSuspense fallback={<Skeleton />}>
      <div className="mb-3 w-full max-w-3xl">
        {userId ? (
          <WebsiteCoin config={config} websiteId={websiteId} asLink={true} />
        ) : (
          <LoggedOut />
        )}
      </div>
    </ClientSuspense>
  )
}

export default function WebsiteCoins() {
  return (
    <Suspense fallback={null}>
      <InnerWebsiteCoins />
    </Suspense>
  )
}
