import ClientSuspense from '@components/ClientSuspense'
import getOrderPath from '@helpers/misc/getOrderPath'
import getQueryObject from '@helpers/misc/getQueryObject'
import Container from '@packages/justo-parts/lib/components/Container'
import StoreName from '@page-components/CheckoutV3/legacyCode/StoreName'
import {useSelectedProduct, useSelectedProductId} from '@page-components/OrderV2/useSelectedProduct'
import {useRouter} from 'next/router'
import React, {Suspense, useCallback, useEffect} from 'react'

import Bar from './Bar'
import Brands from './Brands'
import Filters from './Filters'
import SearchProduct from './SearchProduct'
import SelectProduct from './SelectProduct'
import WebsiteCoins from './WebsiteCoins'

import SpinnerLoading from '@components/V3/loadingIndicators/Spinner'
import {GetCategoriesAndProductsWeb_CachedQuery} from '@data/__generated__/types.main'
import {useFetchUntilCategoryFound} from '@data/queries/categories/getCategoriesAndProducts.main'
import {useWebsiteMenuInformation} from '@data/queries/website/websiteMenuInformation.main'
import classnames from '@helpers/misc/classnames'
import {smoothScrollTo} from '@helpers/misc/smoothScrollTo'
import useIsStore from '@hooks/useIsStore'
import useLayout from '@page-components/Order/useLayout'
import dynamic from 'next/dynamic'
import CategoryNavBar from './CategoryNavBar'
import {useProductPaginationContext} from './ProductPaginationContext'
import ProductsList from './ProductsList'
import styles from './styles.module.css'

export interface ProductsContentProps {
  categories: GetCategoriesAndProductsWeb_CachedQuery['categories']
}

export interface CategoriesRefMap {
  [key: string]: HTMLDivElement
}

const CategoryNavBarCpg = dynamic(() => import('./CategoryNavBar/CategoryNavBarCpg'))

const SCROLL_OFFSET = -120

export type ScrollToSignature = (
  categoryId: string,
  opts?: {callback?: () => void; getOffset?: (offset: number) => number},
) => void

export default function ProductsContent(props: ProductsContentProps) {
  const selectedProductId = useSelectedProductId()
  const selectedProduct = useSelectedProduct()
  const [searchProducts, setSearchProducts] = React.useState(null)
  const router = useRouter()
  const search = router.query?.search as string
  const {website} = useWebsiteMenuInformation()
  const layout = useLayout()
  const categoriesRefs: CategoriesRefMap = {}
  const findCategory = useFetchUntilCategoryFound()
  const {setProductPaginationMode, filter, setFilter} = useProductPaginationContext()
  const [isFetchingCategories, setIsFetchingCategories] = React.useState(false)
  const isStore = useIsStore()
  const [scrollAfterLoadTo, setScrollAfterLoadTo] = React.useState(null)

  useEffect(() => {
    if (search) {
      setFilter(search)
    }
  }, [search])

  const scrollCallback: ScrollToSignature = useCallback(
    (categoryId: string, opts) => {
      const {callback = () => {}, getOffset = offset => offset} = opts || {}
      setFilter('')
      // If we are selecting categories, we disable the automatic pagination to avoid pushing content down and screwing up the scroll
      setProductPaginationMode('manual')

      if (categoriesRefs[categoryId]) {
        smoothScrollTo(categoriesRefs[categoryId], {offset: getOffset(SCROLL_OFFSET), callback})
      } else {
        setIsFetchingCategories(true)
        findCategory(categoryId).then(({found}) => {
          // fallback
          setTimeout(() => {
            setIsFetchingCategories(false)
          }, 10000)

          if (!found) {
            setIsFetchingCategories(false)
          }

          setScrollAfterLoadTo(categoryId)
          callback()
        })
      }
    },
    [categoriesRefs],
  )

  useEffect(() => {
    if (!scrollAfterLoadTo) return
    if (!categoriesRefs[scrollAfterLoadTo]) return

    // We use a timeout to avoid pre-scrolling while re-renders are happening
    const tid = setTimeout(() => {
      smoothScrollTo(categoriesRefs[scrollAfterLoadTo], {
        offset: SCROLL_OFFSET,
        callback: () => {
          setScrollAfterLoadTo(null)
          setIsFetchingCategories(false)
          clearTimeout(tid)
        },
      })
    }, 500)

    return () => clearTimeout(tid)
  }, [scrollAfterLoadTo, categoriesRefs, props.categories])

  const renderSelect = () => {
    return (
      <SelectProduct
        allowEdit={false}
        product={selectedProduct}
        open={!!selectedProductId}
        close={() => {
          const query = {...getQueryObject(router)}
          // biome-ignore lint/performance/noDelete:
          delete query.productId
          router.push({pathname: getOrderPath(router, website), query}, null, {scroll: false})
        }}
      />
    )
  }

  const renderBar = () => {
    return website?.isMultiBrand ? (
      <Brands scrollTo={scrollCallback} onClick={() => setFilter('')} />
    ) : isStore ? (
      <CategoryNavBarCpg
        categoryRefs={categoriesRefs}
        scrollTo={scrollCallback}
        onClick={() => setFilter('')}
      />
    ) : (
      <CategoryNavBar
        categoryRefs={categoriesRefs}
        scrollTo={scrollCallback}
        onClick={() => setFilter('')}
      />
    )
  }

  return (
    <div className={`${styles.container} ${styles[`container_${layout}`]}`}>
      <Suspense fallback={null}>
        <Bar
          filter={filter}
          setFilter={filter => {
            setFilter(filter)
          }}
        >
          {renderBar()}
        </Bar>
      </Suspense>
      <ClientSuspense fallback={null}>
        <SearchProduct categories={props?.categories} onProductSearched={setSearchProducts} />
      </ClientSuspense>
      <Container className={styles.containerWebsiteCoins}>
        <WebsiteCoins />
      </Container>
      <Container className={styles.containerLabel}>
        <div className={styles.containerIcon}>
          <ClientSuspense fallback={null}>
            <StoreName isOnMenu />
          </ClientSuspense>
        </div>
        <div className={styles.containerFilter}>
          <ClientSuspense fallback={null}>
            <Filters />
          </ClientSuspense>
        </div>
      </Container>
      <div
        className={classnames(
          'fixed inset-0 flex justify-center items-center z-50 bg-gray-900 transition-all duration-300',
          {
            hidden: isFetchingCategories === false,
            'bg-opacity-0': isFetchingCategories === false,
            'bg-opacity-75': isFetchingCategories === true,
          },
        )}
      >
        <SpinnerLoading size="lg" />
      </div>
      <ProductsList searchResults={searchProducts} categoriesRefs={categoriesRefs} />
      {renderSelect()}
    </div>
  )
}
