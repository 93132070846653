import {GetCategoriesAndProductsWeb_CachedQuery} from '@data/__generated__/types.main'
import Container from '@packages/justo-parts/lib/components/Container'
import NextOpenDate from '@page-components/Order/NextOpenDate/NextOpenDate.main'
import useLayout from '@page-components/Order/useLayout'
import {Suspense} from 'react'
import {CategoriesRefMap} from '..'
import {useProductPaginationContext} from '../ProductPaginationContext'
import Categories from './Categories'
import SearchedResults from './SearchedResults'

export default function ProductsList({
  searchResults,
  categoriesRefs,
}: {
  searchResults: GetCategoriesAndProductsWeb_CachedQuery['categories']['items'][0]['products']
  categoriesRefs: CategoriesRefMap
}) {
  const {filter} = useProductPaginationContext()
  const layout = useLayout()
  const isSearching = filter && filter !== ''
  const isLayoutV2 = layout === 'v2'

  const Menu = () => {
    return (
      <>
        {isSearching ? (
          <SearchedResults searchResults={searchResults} />
        ) : (
          <Categories categoriesRefs={categoriesRefs} />
        )}
      </>
    )
  }

  return (
    <Suspense fallback={null}>
      {isLayoutV2 ? (
        <Container>
          <NextOpenDate />
          <br />
          <Menu />
        </Container>
      ) : (
        <Menu />
      )}
    </Suspense>
  )
}
